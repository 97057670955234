<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="kits"
          :provider="provider"
          :headers="headers"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :options.sync="options"
          :server-items-length="total"
          item-key="id"
          fixed-header
          :footer-props="{
            'items-per-page-options': [50, 100, 500],
          }"
          :hide-default-footer="total <= 50"
        >
          <template #actions-right>
            <v-btn color="primary" dark class="mr-1" small @click="addNewkit">
              New Kit
            </v-btn>
            <KitDialog v-model="kitDialog" :edited-kit="editedItem" />
          </template>
          <template #item.cameras="{ item }">
            <CamerasColumn :item="item" />
          </template>
          <template #item.actions="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  x-small
                  class="cursor-pointer mx-n1"
                  @click="editKit(item)"
                  v-on="on"
                >
                  <v-icon color="primary" small> fa-pencil-alt </v-icon>
                </v-btn>
              </template>
              <span>Edit Kit</span>
            </v-tooltip>
            &nbsp;
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  x-small
                  class="cursor-pointer mx-n1"
                  @click="deleteKit(item)"
                  v-on="on"
                >
                  <v-icon color="error" small> far fa-trash-alt </v-icon>
                </v-btn>
              </template>
              <span>Delete Kit</span>
            </v-tooltip>
          </template>
          <template #item.name="{ item }">
            <span class="cursor-pointer primary--text">
              {{ item.name }}
            </span>
          </template>
          <template #item.createdAt="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
        </ReportTable>
      </ECol>
    </ERow>
  </v-container>
</template>

<script lang="ts">
import KitsHeader from "@/components/kits/kitsHeaders"
import ReportTable from "@/components/ReportTable"
import CamerasColumn from "@/components/CamerasColumn"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import KitDialog from "@/components/kits/KitDialog"
import countries from "@evercam/shared/constants/countries"

export default {
  name: "Kits",
  components: {
    ReportTable,
    CamerasColumn,
    KitDialog,
  },
  data: () => {
    return {
      headers: KitsHeader,
      countries: countries,
      options: {},
      items: [],
      sortBy: "name",
      sortDesc: false,
      total: 0,
      loading: false,
      kitDialog: false,
      editedItem: {},
    }
  },
  head() {
    return {
      title: "Admin - Kits",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useReportStore),
    provider() {
      return AdminApi.kits.getAllKits
    },
  },
  methods: {
    addNewkit() {
      this.editedItem = {}
      this.kitDialog = true
    },
    editKit(kit) {
      this.editedItem = kit
      this.editedItem.countryCode = this.countries.find(
        (item) => item.name === kit.countryName
      )?.value
      this.kitDialog = true
    },
    async deleteKit(kit) {
      if (
        await this.$confirmDialog.open({
          title: "Delete Kit ?",
          message: `This will delete kit (${kit.name}) permanently!`,
        })
      ) {
        try {
          await AdminApi.kits.deleteKit(kit.id)
          this.reportStore.items = this.reportStore.items.filter(
            (item) => item.id !== kit.id
          )
          this.$notifications.success("Kithas been deleted.")
        } catch (error) {
          this.$notifications.error({
            text: "Delete kit failed!",
            error,
          })
        }
      }
    },
  },
}
</script>
