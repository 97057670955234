export default [
  {
    value: "actions",
    name: "",
    width: 30,
    sortable: false,
    filterable: false,
  },
  {
    value: "name",
    text: "Name",
    width: 110,
    sortable: true,
    filterable: true,
  },
  {
    value: "kitSerial",
    text: "Serial #",
    width: 110,
    sortable: true,
    filterable: true,
  },
  {
    value: "kitStatus",
    text: "Status",
    width: 110,
    sortable: true,
  },
  {
    value: "projectName",
    text: "Project",
    width: 110,
    sortable: true,
    filterable: true,
  },
  {
    value: "cameras",
    text: "Cameras",
    width: 110,
    sortable: false,
    filterable: true,
  },
  {
    value: "location",
    text: "Location",
    width: 110,
    sortable: true,
    filterable: true,
  },
  {
    value: "countryName",
    text: "Country",
    width: 110,
    sortable: true,
  },
  {
    value: "type",
    text: "type",
    width: 110,
    sortable: true,
  },
  {
    value: "createdAt",
    text: "Created At",
    width: 150,
    sortable: true,
  },
]
